import { IAuthUser } from '../../core';
import { struct, typeOf, array, dictionary, Static, union, readonly, eqAny } from '../../utils/Types';

export const isIdTokenResponse = readonly(struct().required({ id_token: typeOf('string') }));

export const isRefreshTokenResponse = readonly(struct().required({ refresh_token: typeOf('string') }));

export const isAccessTokenResponse = readonly(
  struct().required({
    access_token: typeOf('string'),
    expires_in: typeOf('number'),
    scope: typeOf('string')
  })
);

export const isErrorResponse = readonly(
  struct()
    .required({ error: typeOf('string') })
    .optional({ error_description: typeOf('string'), correlation_id: typeOf('string') })
    .intersect(readonly(dictionary(typeOf('string'))))
);

export const isPkceRequestContext = readonly(
  struct().required({
    tenantId: typeOf(['string', 'undefined']),
    accessScopes: readonly(array(typeOf('string'))),
    extraQueryParameters: readonly(dictionary(typeOf('string'))),
    isLogin: eqAny([true, undefined] as const),
    verifier: typeOf('string'),
    restoreUri: typeOf('string'),
    state: typeOf('string')
  })
);

const isPkceSuccessContext = readonly(
  struct().required({
    tenantId: typeOf(['string', 'undefined']),
    accessScopes: readonly(array(typeOf('string'))),
    extraQueryParameters: readonly(dictionary(typeOf('string'))),
    isLogin: eqAny([true, undefined] as const),
    code: typeOf('string'),
    verifier: typeOf('string')
  })
);

const isPkceErrorContext = readonly(
  struct().required({
    tenantId: typeOf(['string', 'undefined']),
    accessScopes: readonly(array(typeOf('string'))),
    extraQueryParameters: readonly(dictionary(typeOf('string'))),
    isLogin: eqAny([true, undefined] as const),
    error: typeOf('string'),
    errorDescription: typeOf(['string', 'undefined']),
    correlationId: typeOf(['string', 'undefined']),
    other: readonly(dictionary(typeOf('string')))
  })
);

export const isPkceResponseContext = union(isPkceSuccessContext, isPkceErrorContext);

const isAccessTokenEntry = readonly(
  struct().required({
    token: typeOf('string'),
    expireTimestamp: typeOf('number')
  })
);

export const isIdentity = readonly(
  struct().required({
    username: typeOf('string'),
    tenantId: typeOf('string'),
    rawClaims: dictionary()
  })
);

export const isSessionData = readonly(
  struct().required({
    identity: isIdentity,
    accessTokens: readonly(dictionary(isAccessTokenEntry)),
    interactiveTokenErrors: readonly(dictionary())
  })
);

export const isEndpointsCache = readonly(
  dictionary(
    struct().required({
      authorize: typeOf('string'),
      token: typeOf('string'),
      logout: typeOf('string')
    })
  )
);

export const isOpenIdConfigResponse = struct().optional({
  authorization_endpoint: typeOf('string'),
  token_endpoint: typeOf('string'),
  end_session_endpoint: typeOf('string')
});

/**
 * Context saved to browser storage before making a PKCE auth code flow
 * redirect to the STS `/authorize` endpoint.
 */
export type PkceRequestContext = Static<typeof isPkceRequestContext>;

/**
 * Context saved to history state when restoring the original page URI, after
 * an `/authorize` redirect. These values were received via the URL when the
 * auth code flow redirected back to the application.
 */
export type PkceResponseContext = Static<typeof isPkceResponseContext>;
export type PkceSuccessContext = Static<typeof isPkceSuccessContext>;
export type PkceErrorContext = Static<typeof isPkceErrorContext>;

/**
 * Information about one saved access token.
 */
export type AccessTokenEntry = Static<typeof isAccessTokenEntry>;

/**
 * Saved identity of the logged in user.
 */
export type Identity = Static<typeof isIdentity>;

/**
 * User session data saved across page reloads as long as a user is logged in.
 * This contains the _minimum_ amount of information to rehydrate the session
 * _via a redirect to `/authorize`._ It does _not_ contain any refresh tokens
 * because it is expected to be stored insecurely in browser storage. It does
 * contain _access_ tokens in order to enhance the user experience. Access
 * tokens are short lived and cannot be exchanged, making them relatively
 * safe to persist in browser storage.
 */
export type SessionData = Static<typeof isSessionData>;

/**
 * Parameters received from STS in the redirect URL.
 */
export type PkceResponse =
  | {
      readonly code: string;
      readonly state?: string;
    }
  | ({
      readonly error: string;
      readonly error_description?: string;
      readonly correlation_id?: string;
    } & Readonly<Record<string, string>>);

/**
 * Grant information required to get an access token.
 */
export type Grant =
  | {
      readonly type: 'authorization_code';
      readonly code: string;
      readonly verifier: string;
    }
  | {
      readonly type: 'refresh_token';
      readonly token: string;
    };

/**
 * Information about one access token.
 */
export type AccessToken = {
  readonly value: string;
  readonly tenantId: string;
  readonly accessScopes: readonly string[];
  readonly expireTimestamp: number;
};

/**
 * token details received from the `/token` endpoint.
 */
export type TokensResponse = {
  readonly user?: IAuthUser;
  readonly access?: AccessToken;
  readonly refresh?: string;
};
