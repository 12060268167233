import { AuthError, AuthErrorCause } from '../../core';
import { getCleanRecord } from '../../utils/Types';

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes#handling-error-codes-in-your-application
 */
const ERROR_CAUSE_TO_CODES: Readonly<Record<Exclude<AuthErrorCause, 'unknown'>, ReadonlySet<string>>> = {
  configuration: new Set(),
  client: new Set([
    'invalid_request',
    'invalid_client',
    'invalid_resource',
    'invalid_grant',
    'unauthorized_client',
    'unsupported_grant_type'
  ]),
  server: new Set(['server_error']),
  transient: new Set('temporarily_unavailable'),
  interaction_required: new Set(['interaction_required'])
};

const ERROR_CAUSES = Object.keys(ERROR_CAUSE_TO_CODES) as readonly (keyof typeof ERROR_CAUSE_TO_CODES)[];

export function getError(
  error: string,
  description: string | undefined,
  correlationId: string | undefined,
  other: Record<string, string | undefined>
): AuthError {
  other = getCleanRecord(other);

  if (description != null) {
    other = { ..._getDescriptionValues(description), ...other };
  }

  return new AuthError(_getErrorCause(error), description, {
    internalCode: error,
    correlationId: correlationId || other.correlationId || other.correlation_id,
    other
  });
}

function _getErrorCause(code: string): AuthErrorCause {
  for (const cause of ERROR_CAUSES) {
    if (ERROR_CAUSE_TO_CODES[cause].has(code)) {
      return cause;
    }
  }

  return 'unknown';
}

function _getDescriptionValues(description: string): Record<string, string> {
  const regex = /^[\s\r\n]*([^:=\r\n]+?)\s*[:=]\s*(.*?)[\s\r\n]*$/gm;
  const values: Record<string, string> = {};
  let match: RegExpMatchArray | null;

  while (null != (match = regex.exec(description))) {
    const [, key, value] = match;
    values[key.toLowerCase().replace(/\s+/g, '_')] = value;
  }

  return values;
}
