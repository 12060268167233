import { IAbortSignal } from './IAbortSignal';

export class AbortSignalPolyfill implements IAbortSignal {
  public _listeners: (() => void)[] = [];
  public _aborted = false;

  public get aborted() {
    return this._aborted;
  }

  public addEventListener(name: 'abort', listener: () => void) {
    if (name === 'abort') {
      this._listeners.push(listener);
    }
  }

  public removeEventListener(name: 'abort', listener: () => void) {
    if (name === 'abort') {
      this._listeners = this._listeners.filter(l => l === listener);
    }
  }
}
