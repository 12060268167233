import { getRandomValues } from './getRandomValues';

/**
 * RFC4122: Version 4 UUID (random).
 */
export function getUuid(): string {
  const bytes = new Uint8Array(16);

  getRandomValues(bytes);

  // bytes 6-7 represents the time_hi_and_version field. We will set the
  // four most significant bits (4 through 7) of byte 6 to represent
  // decimal number 4 (UUID version number).
  bytes[6] |= 0x40; // Set the 6 bit to 1.
  bytes[6] &= 0x4f; // Set the 4, 5, and 7 bit to 0.

  // byte 8 represents the clock_seq_hi_and_reserved field. We will set
  // the two most significant bits (6 and 7) of the clock_seq_hi_and_reserved
  // to zero and one, respectively.
  bytes[8] |= 0x80; // Set the 7 bit to 1.
  bytes[8] &= 0xbf; // Set the 6 bit to 0.

  return (
    _hex(bytes[0]) +
    _hex(bytes[1]) +
    _hex(bytes[2]) +
    _hex(bytes[3]) +
    '-' +
    _hex(bytes[4]) +
    _hex(bytes[5]) +
    '-' +
    _hex(bytes[6]) +
    _hex(bytes[7]) +
    '-' +
    _hex(bytes[8]) +
    _hex(bytes[9]) +
    '-' +
    _hex(bytes[10]) +
    _hex(bytes[11]) +
    _hex(bytes[12]) +
    _hex(bytes[13]) +
    _hex(bytes[14]) +
    _hex(bytes[15])
  );
}

function _hex(num: number) {
  let hex = num.toString(16);

  while (hex.length < 2) {
    hex = '0' + hex;
  }

  return hex;
}
