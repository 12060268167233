/* istanbul ignore file */
import { getStoreKey } from '../../utils/Store';
import { isPkceRequestContext, isSessionData, isEndpointsCache, isPkceResponseContext } from './types';

export const STORE_PREFIX = '@iamexperiences/react-auth/v1';
export const STORE_PKCE_REQUEST_CONTEXT_KEY = getStoreKey('pkce_request_context', isPkceRequestContext);
export const STORE_PKCE_RESPONSE_CONTEXT_KEY = getStoreKey('pkce_response_context', isPkceResponseContext);
export const STORE_SESSION_KEY = getStoreKey('session', isSessionData);
export const STORE_ENDPOINTS_CACHE_KEY = getStoreKey('endpoints', isEndpointsCache);

export const SESSION_UPDATE_INTERVAL = 3_300_000; // 55 minutes
export const TOKEN_GC_INTERVAL = 300_000; // 5 minutes
export const TOKEN_EXPIRATION_OFFSET = 60_000; // 1 minute
