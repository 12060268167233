import { isGuestUsername } from './isGuestUsername';
import { getNormalUsername } from './getNormalUsername';

/**
 * + Find an "email" style username, that is _not_ a guest username.
 * + Fallback to a guest username, converted to a normal username.
 * + Fallback to the first value if nothing looks like an email.
 */
export function getBestUsername(values: string[]): [string | undefined, boolean] {
  let result = values.find(value => value.indexOf('@') >= 0 && !isGuestUsername(value));

  if (result != null) {
    return [result, false];
  }

  result = values.find(isGuestUsername);

  return result != null ? [getNormalUsername(result), true] : [values[0], false];
}
