import { getBestUsername } from './getBestUsername';
import { getAuthorityTenant } from './getAuthorityTenant';
import { getBestEmail } from './getBestEmail';
import { IAuthUser } from './types';

export type AuthUserOptions = {
  [P in keyof Omit<IAuthUser, 'homeTenantId' | 'isGuest' | 'rawClaims'>]-?: unknown[] | undefined;
} & {
  homeTenantId?: unknown[];
  rawClaims: {};
};

/**
 * Given user claims multi-map, generate an `IAuthUser` object.
 */
export function getAuthUser(options: AuthUserOptions): IAuthUser {
  const { username, issuer, identityProvider, tenantId, homeTenantId, email, name, objectId, rawClaims } = options;

  const [normalUsername, isGuestUsername] = getBestUsername(_strings(username));
  const normalTenantId = _first(_strings(tenantId));
  const normalIssuer = _first(_strings(issuer));
  const normalIdentityProvider = _first(_strings(identityProvider));
  const extractedHomeTenantId = getAuthorityTenant(normalIdentityProvider);
  const normalHomeTenantId = _first(_strings(homeTenantId)) ?? extractedHomeTenantId;
  const normalEmail = getBestEmail(_strings([...email, normalUsername]));
  const isGuestTenant = normalTenantId !== normalHomeTenantId && normalTenantId != null && normalHomeTenantId != null;
  const isGuestIssuer = normalIdentityProvider != null && normalIdentityProvider !== normalIssuer;
  const isGuest = isGuestTenant || isGuestIssuer || isGuestUsername;

  const user: IAuthUser = {
    username: normalUsername ?? '',
    tenantId: normalTenantId ?? '',
    issuer: normalIssuer ?? '',
    identityProvider: normalIdentityProvider ?? normalIssuer ?? '',
    homeTenantId: normalHomeTenantId ?? (isGuest ? undefined : normalTenantId),
    email: normalEmail,
    name: _first(_strings(name)) ?? normalUsername ?? normalEmail,
    objectId: _first(_strings(objectId)) ?? '',
    isGuest: isGuestTenant || isGuestIssuer || isGuestUsername,
    rawClaims
  };

  return user;
}

function _strings(values: any[] = []): string[] {
  return values
    .filter(v => typeof v === 'string')
    .map(v => (v as string).trim())
    .filter(v => v !== '');
}

function _first(value: string[]): string | undefined {
  return value[0];
}
