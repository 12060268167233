import { AuthenticationParameters, UserAgentApplication } from 'msal';
import { getAuthError } from './getAuthError';
import { getPrompt } from './getPrompt';
import { getUser } from './getUser';
import {
  AuthError,
  withAuthLifecycle,
  getTenantAuthority,
  getNormalScopes,
  getAuthManager,
  getNormalUsername,
  getAutoLoginResult,
  IAuthLoginHints,
  IAuthTokenHints,
  IAuthUser,
  IAuthState,
  IAuthManager,
  IAuth,
  IAuthError,
  IAuthInitOptions
} from '../../core';
import { getCleanRecord } from '../../utils/Types';
import { getStore } from '../../utils/Store';
import { ILoggerFactory } from '../../utils/Logger';
import { ISubjectReadonly } from '../../utils/Subject';
import { once } from '../../utils/Decorators';

const STORE_PREFIX = '@iamexperiences/react-auth/MSAL-v1';

export interface IAuthMsalOptions {
  clientId: string;
  authority: string;
  msalFactory: (clientId: string, authority: string) => UserAgentApplication;
  loggerFactory?: ILoggerFactory;
}

/**
 * MSAL.js implementation of the `IAuth` interface.
 *
 * __The `msal` peer dependency must be installed when using this class!__
 */
export class AuthMsal implements IAuth {
  private _manager: IAuthManager;
  private _savedAuthority: string | null | undefined;
  private _getMsal: () => UserAgentApplication;

  public readonly clientId: string;
  public readonly authority: string;
  public readonly user: ISubjectReadonly<IAuthUser | null>;
  public readonly isReady: ISubjectReadonly<boolean>;
  public readonly isRedirecting: ISubjectReadonly<boolean>;
  public readonly tokenError: ISubjectReadonly<IAuthError | null>;
  public readonly loginError: ISubjectReadonly<IAuthError | null>;

  public get auth(): UserAgentApplication {
    return this._getMsal();
  }

  constructor({ clientId, authority, msalFactory, loggerFactory }: IAuthMsalOptions) {
    const store = getStore([STORE_PREFIX, clientId, authority]);

    this._manager = getAuthManager({
      clientId,
      authority,
      store,
      loggerFactory,
      cacheErrors: true
    });
    this._getMsal = once(() => {
      this._manager.assertInitialized();
      return msalFactory(clientId, authority);
    });

    this.clientId = clientId;
    this.authority = authority;

    this.user = this._manager.state.user;
    this.isReady = this._manager.state.isReady;
    this.isRedirecting = this._manager.state.isRedirecting;
    this.tokenError = this._manager.state.tokenError;
    this.loginError = this._manager.state.loginError;

    withAuthLifecycle(this, this._manager, loggerFactory, store);
  }

  public get state(): IAuthState {
    return this._manager.state;
  }

  public init(options: IAuthInitOptions = {}): void {
    const auth = this._getMsal();
    const manager = this._manager;
    const {
      state: { user }
    } = manager;

    // The authority is set based on the user's tenant. Save the initial
    // authority so that it can be restored if the user is unset.
    this._savedAuthority = auth.authority;
    user.subscribe(this._updateAuthority);

    // Checking this early incase handleRedirectCallback() clears it
    // synchronously.
    const loginInProgress = auth.getLoginInProgress();
    const isCallback = auth.isCallback(window.location.hash);

    // MSAL requires that this be set before calling login. So basically, it
    // should always be set, even if we know it won't be called (e.g. when no
    // login is in progress).
    auth.handleRedirectCallback((err, response) => {
      if (err == null && response == null) {
        // This _might_ be called even if no login error or response is received.
        this._handleNonRedirect(options);
        return;
      }

      const user = getUser(response?.account != null ? response?.account : auth.getAccount());

      manager.setLoginError(err != null ? getAuthError(err) : null);

      if (user != null) {
        manager.setUser(user);
      }

      manager.setReady();
    });

    if (!loginInProgress && !isCallback) {
      // The above callback shouldn't be called in this case (in theory). But
      // if it is, it shouldn't cause a problem.
      this._handleNonRedirect(options);
    }
  }

  public login(hints?: IAuthLoginHints): void {
    this._login(hints);
  }

  public logout(): void {
    this._manager.setRedirect(() => this._getMsal().logout());
  }

  public async acquireToken(resource?: string | readonly string[], hints: IAuthTokenHints = {}): Promise<string> {
    const auth = this._getMsal();
    const { tenantId, prompt } = hints;
    const manager = this._manager;
    const {
      state: { clientId }
    } = manager;
    // The current client ID is the exception to rule requiring /.default to
    // be prepended to the end of audience UUIDs.
    const scopes = getNormalScopes(resource) ?? [clientId];
    const authority = getTenantAuthority(auth.authority, tenantId) || auth.authority;
    const request: AuthenticationParameters = getCleanRecord({
      scopes,
      authority
    });

    let authError: AuthError | undefined;

    // Currently, this if will always be true. But it may not be if additional
    // prompt options are added in the future.
    if (prompt !== 'interactive') {
      try {
        const response = await auth.acquireTokenSilent(request);
        const token = response.accessToken ?? response.idToken?.rawIdToken;

        if (token == null) {
          throw new AuthError('unknown', 'no token returned');
        }

        return token;
      } catch (err) {
        authError = getAuthError(err);

        if (prompt === 'none' || authError.cause !== 'interaction_required') {
          throw authError;
        }
      }
    }

    // Now add the prompt hint, because we've eliminated the "none" case, so
    // any remaining prompt hint makes sense now that we're getting the token
    // interactively.
    request.prompt = getPrompt(prompt);

    // TODO: Verify that this actually gets the claims challenge.
    const claimsChallenge = authError?.details.other?.claims;

    // Add the claims request to handle the CA policy case where specific
    // claims must be present in the token to enforce the policy.
    if (typeof claimsChallenge === 'string' && claimsChallenge) {
      request.claimsRequest = claimsChallenge;
    }

    return manager.setRedirect(() => auth.acquireTokenRedirect(request));
  }

  private _handleNonRedirect({ autoLogin = false }: Pick<IAuthInitOptions, 'autoLogin'>) {
    const autoLoginResult = getAutoLoginResult(autoLogin);

    if (autoLoginResult != null) {
      const { hints, url } = autoLoginResult;

      if (url) {
        window.history.replaceState(window.history.state, window.document.title, url);
      }

      this._login(hints);

      return;
    }

    const auth = this._getMsal();
    const manager = this._manager;
    const user = getUser(auth.getAccount());

    if (user != null) {
      manager.setUser(user);
    }

    manager.setReady();
  }

  private _login(hints: IAuthLoginHints = {}): void {
    const { tenantId, prompt, username, domain, restoreUri, extraQueryParameters = {}, resource } = hints;
    const auth = this._getMsal();
    const request = getCleanRecord<AuthenticationParameters>({
      loginHint: getNormalUsername(username),
      authority: getTenantAuthority(auth.authority, tenantId),
      prompt: getPrompt(prompt),
      extraQueryParameters: {
        ...(domain != null ? { domain_hint: domain } : {}),
        ...extraQueryParameters
      },
      extraScopesToConsent: getNormalScopes(resource)
    });

    this._manager.setRedirect(() => {
      if (restoreUri) {
        window.history.pushState(window.history.state, window.document.title, restoreUri);
      }
      auth.loginRedirect(request);
    });
  }

  private _updateAuthority = (user: IAuthUser | null): void => {
    const auth = this._getMsal();

    if (user != null) {
      const authority = getTenantAuthority(auth.authority, user.tenantId);

      if (authority && authority !== auth.authority) {
        auth.authority = authority;
      }
    } else if (this._savedAuthority && this._savedAuthority !== auth.authority) {
      auth.authority = this._savedAuthority;
    }
  };
}
