import { ItemType } from './types';
import { Guard, guard } from './guard';

/**
 * Create a guard which matches a specific value. The guard type will be the
 * value's type.
 *
 * ```ts
 * const isString: Guard<string> = eq('foo');
 * const isFoo: Guard<'foo'> = eq('foo' as const);
 * ```
 */
export function eq<TValue>(value: TValue): Guard<TValue> {
  return guard((x: any): x is TValue => x === value);
}

export const nul = eq(null);

/**
 * Create a guard which matches any of an array of specific values. The guard
 * type will be the union of the value types.
 *
 * ```ts
 * const isNumberOrString: Guard<number | string> = eqAny([1, 'one']);
 * const isOne: Guard<1 | 'one'> = eqAny([1, 'one'] as const);
 * ```
 */
export function eqAny<TValues extends readonly [any, ...any[]]>(values: TValues): Guard<ItemType<TValues>> {
  return guard((x: any): x is ItemType<TValues> => values.includes(x));
}
