import { IStoreKey } from './IStoreKey';

export function getStoreKey<T>(
  name: string,
  guard: (value: any) => value is T = (_: any): _ is any => true
): IStoreKey<T> {
  return {
    name,
    guard,
    item(itemName: string): IStoreKey<any> {
      return getStoreKey(`${name}/${itemName}`, guard);
    }
  };
}
