import React from 'react';

import { IAuth, IAuthUser, IAuthError } from '../core';

export interface IAuthContext {
  auth: IAuth;

  /**
   * The currently logged-in user's information, or null if no user is
   * logged-in.
   */
  user: IAuthUser | null;

  /**
   * Indicates whether or not auth-aware components should render and/or
   * perform effects.
   */
  isReady: boolean;

  /**
   * Indicates whether or not an auth login/token redirect is in progress.
   */
  isRedirecting: boolean;

  /**
   * Login error or null.
   */
  error: IAuthError | null;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
