/**
 * Get an authority URL scoped to the given tenant ID.
 *
 * Requires that the authority be an HTTPS URL where the first path component
 * is the tenant (e.g. `https://login.microsoftonline.net/<tenant>/`).
 */
export const getTenantAuthority = (authority: string, tenantId?: string): string => {
  if (!tenantId) {
    return authority;
  }

  const match = authority.match(/^(https:\/{2}[^/]+\/)(?:[^/]+)(.*)$/);

  if (match == null) {
    throw new Error(`Authority URL does not have the expected format: ${authority}`);
  }

  const [, prefix, suffix] = match;

  return `${prefix}${tenantId}${suffix}`;
};
