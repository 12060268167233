/**
 * Delete all of the undefined properties on an object.
 */
export function getCleanRecord<T extends Record<string, any>>(value: T): T;
/**
 * Delete all of the undefined properties on an object. Returns undefined if
 * the value is undefined;
 */
export function getCleanRecord<T extends Record<string, any>>(value?: T): T | undefined;
export function getCleanRecord<T extends Record<string, any>>(value?: T): T | undefined {
  if (value == undefined) return;

  Object.keys(value).forEach(key => {
    if (value[key as keyof T] === undefined) {
      delete value[key as keyof T];
    }
  });

  return value;
}
