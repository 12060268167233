import { NonEmptyArray } from './types';
import { Guard, GuardFn, Static, guard } from './guard';

/**
 * Create a guard which matches any object (including arrays, but not `null`
 * values). The guard type has an index signature of string or number keys,
 * and unknown values.
 */
export function dictionary(): Guard<Record<string, unknown>>;
/**
 * Create a guard which matches an object (including arrays, but not `null`
 * values) when all of its values match one of the given type guards. The
 * guard type has an index signature of string or number keys, and a value
 * type union the given type guard types.
 *
 * ```ts
 * const isStringDict: Guard<Record<string, string | string[]>> = dictionary(typeOf('string'), array(typeOf('string')))
 * ```
 */
export function dictionary<TGuardFns extends NonEmptyArray<GuardFn<any>>>(
  ...fns: TGuardFns
): Guard<Record<string, Static<TGuardFns>>>;
export function dictionary<TGuardFns extends GuardFn<any>[]>(
  ...fns: TGuardFns
): Guard<Record<string, Static<TGuardFns>>> {
  return guard(
    (x: Record<string, unknown>): x is Record<string, Static<TGuardFns>> =>
      x != null &&
      typeof x === 'object' &&
      (fns.length === 0 || fns.some(fit => Object.keys(x).every(key => fit(x[key]))))
  );
}

/**
 * Match any object (including arrays, but not `null` values). The guard type
 * has an index signature of string or number keys, and unknown values.
 */
export const isDictionary = dictionary();
