import { AuthLoginPrompt, AuthTokenPrompt } from '../../core';

/**
 * Map the generic login prompt hint to the MSAL prompt string equivalent.
 *
 * @param prompt react-auth prompt hint.
 */
export const getPrompt = (prompt?: AuthLoginPrompt | AuthTokenPrompt): string | undefined => {
  switch (prompt) {
    case 'none':
      return 'none';
    case 'account':
      return 'select_account';
    case 'credentials':
      return 'login';
  }

  return undefined;
};
