import { GUEST_USERNAME_REGEX } from './constants';

/**
 * If the username is a "guest" username (containing `#EXT#@`), then convert
 * it back to a normal username.
 */
export function getNormalUsername(username?: string): string | undefined {
  const match = username?.match(GUEST_USERNAME_REGEX);

  if (match == null) {
    return username;
  }

  const [, alias, domain] = match;
  const homeUsername = !domain ? alias : `${alias}@${domain}`;

  return homeUsername;
}
