import { SubjectCallback, ISubjectReadonly } from './ISubjectReadonly';
import { SubjectUnsubscribe, ISubscription } from './ISubscription';
import { ISubjectOptions } from './ISubjectOptions';
import { ISubject } from './ISubject';

interface ISubscriber<T> {
  callback: SubjectCallback<T>;
  unsubscribe: SubjectUnsubscribe;
  removed: boolean;
}

/**
 * Create an observable value.
 */
export function getSubject<T>(
  value: T,
  { shouldNotify = (value, oldValue) => value !== oldValue, normalize }: ISubjectOptions<T> = {}
): ISubject<T> {
  const subscribers: ISubscriber<T>[] = [];
  const newSubject: ISubject<T> = Object.defineProperties((): T => value, {
    value: {
      get: () => value
    },
    readonly: {
      get: (): ISubjectReadonly<T> =>
        Object.defineProperties((): T => value, {
          value: {
            get: () => value
          },
          subscribe: {
            value: newSubject.subscribe
          },
          wait: {
            value: newSubject.wait
          },
          waitFor: {
            value: newSubject.waitFor
          },
          require: {
            value: newSubject.require
          }
        })
    },
    subscribe: {
      value: (callback: SubjectCallback<T>, immediate = true): ISubscription => {
        const subscriber: ISubscriber<T> = {
          callback,
          unsubscribe: () => unsubscribe(subscriber),
          removed: false
        };

        subscribers.push(subscriber);

        if (immediate) {
          try {
            callback(value, subscriber.unsubscribe);
          } catch (err) {
            subscriber.unsubscribe();
            throw err;
          }
        }

        return { unsubscribe: subscriber.unsubscribe };
      }
    },
    wait: {
      value: (): Promise<T> => {
        return new Promise<T>(resolve => {
          newSubject.subscribe((value, unsubscribe) => {
            unsubscribe();
            resolve(value);
          }, false);
        });
      }
    },
    waitFor: {
      value: <T0 extends T>(guard: (x: T) => x is T0): Promise<T0> => {
        return new Promise<T0>(resolve => {
          newSubject.subscribe((value, unsubscribe) => {
            if (guard(value)) {
              unsubscribe();
              resolve(value);
            }
          });
        });
      }
    },
    require: {
      value: <T0 extends T>(
        guard: (v: T) => v is T0,
        getError: string | (() => string | Error) = 'value does not meet required constraint'
      ): T0 => {
        if (!guard(value)) {
          let error = typeof getError === 'function' ? getError() : getError;
          if (typeof error === 'string') error = Error(error);

          throw error;
        }

        return value;
      }
    },
    next: {
      value: (newValue: T): void => {
        const oldValue = value;
        value = normalize != null ? normalize(newValue) : newValue;

        if (shouldNotify(value, oldValue)) {
          // Cancel the immediate callbacks if the value is updated before they can fire.
          [...subscribers].forEach(({ callback, unsubscribe, removed }) => {
            if (!removed) {
              callback(value, unsubscribe);
            }
          });
        }
      }
    }
  });

  function unsubscribe(subscriber: ISubscriber<T>): void {
    subscriber.removed = true;
    const i = subscribers.indexOf(subscriber);

    if (i >= 0) {
      subscribers.splice(i, 1);
    }
  }

  return newSubject;
}
