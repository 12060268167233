import { LoggerLevelDebug, LoggerLevelInfo, LoggerLevelWarn, LoggerLevelError } from './constants';
import { ILogger } from './ILogger';
import { ILoggerFactory } from './ILoggerFactory';
import { ILoggerLevel } from './ILoggerLevel';
import { ILoggerEntry } from './ILoggerEntry';

export function getLogger(_parent: ILoggerFactory, _name: string, _sessionId: string): ILogger {
  const _logger: ILogger = {
    log: (level: ILoggerLevel, ...args: [any, any?]): void => {
      if (_parent.getLevel(_name).includes(level)) {
        _parent.getHandler()(_getEntry(level, args));
      }
    },
    debug: (...args: [any, any?]): void => _logger.log(LoggerLevelDebug, ...args),
    info: (...args: [any, any?]): void => _logger.log(LoggerLevelInfo, ...args),
    warn: (...args: [any, any?]): void => _logger.log(LoggerLevelWarn, ...args),
    error: (...args: [any, any?]): void => _logger.log(LoggerLevelError, ...args),
    throwError: (error: Error | string): never => {
      if (typeof error === 'string') {
        error = Error(error);
      }

      const entry = _getEntry(LoggerLevelError, [error.message, error]);

      if (_parent.getLevel(_name).includes(LoggerLevelError)) {
        _parent.getHandler()(entry);
      }

      throw error;
    }
  };

  function _getEntry(level: ILoggerLevel, args: [any, any?]): ILoggerEntry {
    let data: any;
    let message: string | null;

    if (args.length > 1) {
      data = args[1];
      message = args[0] != null ? `${args[0]}` : null;
    } else if (typeof args[0] === 'string') {
      data = undefined;
      message = args[0];
    } else {
      data = args[0];
      message = typeof data?.message === 'string' ? data.message : null;
    }

    return {
      level,
      sessionId: _sessionId,
      name: _name,
      message,
      ...(data !== undefined ? { data } : {})
    };
  }

  return _logger;
}
