import { LoggerLevelInfo } from './constants';
import { getLogger } from './getLogger';
import { ILoggerFactory } from './ILoggerFactory';
import { ILogger } from './ILogger';
import { ILoggerLevel } from './ILoggerLevel';
import { getLoggerLevel } from './getLoggerLevel';
import { ILoggerHandler } from './ILoggerHandler';
import { DefaultLoggerHandler } from './DefaultLoggerHandler';

const SESSION_STORAGE_DEBUG_KEY = 'LOGGER_DEBUG_OVERRIDE';
const LEVEL_DEBUG_OVERRIDE = getLoggerLevel('DEBUG_OVERRIDE', Number.MIN_VALUE);

export function getLoggerFactory(_sessionId: string) {
  let _handler = DefaultLoggerHandler;
  const _levels = new Map<string | undefined, ILoggerLevel>();
  const _loggers = new Map<string, ILogger>();
  const factory: ILoggerFactory = {
    setHandler: (handler: ILoggerHandler = DefaultLoggerHandler): ILoggerFactory => {
      _handler = handler;
      return factory;
    },
    getHandler: (): ILoggerHandler => {
      return _handler;
    },
    setLevel: (level: ILoggerLevel, name?: string): ILoggerFactory => {
      _levels.set(name, level);
      return factory;
    },
    getLevel: (name?: string): ILoggerLevel => {
      if (typeof window !== 'undefined' && window.sessionStorage.getItem(SESSION_STORAGE_DEBUG_KEY) != null) {
        return LEVEL_DEBUG_OVERRIDE;
      }

      return _levels.get(name) ?? LoggerLevelInfo;
    },
    clearLevel: (name?: string): ILoggerFactory => {
      _levels.delete(name);
      return factory;
    },
    getLogger(name: string): ILogger {
      let logger = _loggers.get(name);

      if (logger == null) {
        logger = getLogger(this, name, _sessionId);
        _loggers.set(name, logger);
      }

      return logger;
    }
  };

  return factory;
}
