/**
 * Wrap a function so that it's only ever called once. All subsequent calls
 * return the same value.
 */
export function once<T extends (...args: any[]) => any>(init: T): () => ReturnType<T> {
  let initialized = false;
  let value: any;

  return function (this: any, ...args: Parameters<T>): ReturnType<T> {
    if (!initialized) {
      value = init.apply(this, args);
      initialized = true;
    }

    return value;
  };
}
