import React, { useEffect, useRef, useState } from 'react';
import { AuthContext } from './AuthContext';
import { IAuth, IAuthError } from '../core';
import { ISubscription } from '../utils/Subject';

export interface IAuthProviderProps {
  /**
   * The auth system specific implementation (e.g. MSAL) that descendent
   * "auth-aware" children will use.
   */
  auth: IAuth;
}

/**
 * Provide an `IAuth` implementation to children which are "auth-aware".
 */
export const AuthProvider: React.FC<IAuthProviderProps> = ({ children, auth }) => {
  const [user, setUser] = useState(auth.user());
  const [isReady, setIsReady] = useState(auth.isReady());
  const [isRedirecting, setIsRedirecting] = useState(auth.isRedirecting());
  const [error, setError] = useState<IAuthError | null>(auth.loginError());
  const subscriptions = useRef<readonly ISubscription[]>([]);

  useEffect(() => {
    subscriptions.current = [
      auth.user.subscribe(value => setUser(value)),
      auth.isReady.subscribe(value => setIsReady(value)),
      auth.isRedirecting.subscribe(value => setIsRedirecting(value)),
      auth.loginError.subscribe(value => setError(value))
    ];
    return () => subscriptions.current.forEach(subscription => subscription.unsubscribe());
  }, [auth]);

  return <AuthContext.Provider value={{ auth, user, isReady, isRedirecting, error }}>{children}</AuthContext.Provider>;
};
