import { PkceResponseContext, PkceResponse, PkceRequestContext } from './types';
import { STORE_PKCE_REQUEST_CONTEXT_KEY, STORE_PKCE_RESPONSE_CONTEXT_KEY } from './constants';
import { IOpenIdConfigClient } from './getOpenIdConfigClient';
import { getPkceCodes } from './getPkceCodes';
import { AuthError, AuthLoginPrompt, IAuthManager } from '../../core';
import { getCleanRecord } from '../../utils/Types';
import { getUuid } from '../../utils/Crypto';
import { ILoggerFactory } from '../../utils/Logger';
import { IStore } from '../../utils/Store';
import { getPageLoadStats } from '../../core/getPageLoadStats';

/**
 * Capture the window url hash _immediately upon page load_ so that even if
 * the URL is changed before `IAuth.init()` is called, we can still correctly
 * handle redirect parameters.
 */
const pageLoadHash = window.location.hash;

export interface IOAuthAuthorizeClientOptions {
  readonly redirectUri: string;
  readonly loggerFactory: ILoggerFactory;
  readonly store: IStore;
  readonly manager: IAuthManager;
  readonly openIdConfigClient: IOpenIdConfigClient;
}

export interface IOAuthAuthorizeOptions {
  readonly tenantId?: string;
  readonly username?: string;
  readonly domain?: string;
  readonly consentScopes?: readonly string[];
  readonly accessScopes?: readonly string[];
  readonly prompt?: AuthLoginPrompt;
  readonly restoreUri?: string;
  readonly extraQueryParameters?: Readonly<Record<string, string>>;
  /**
   * If true, the authorize redirect is _definitely_ for a login. If
   * undefined, then whether or not it's a login redirect depends on whether
   * there's an current user saved in the session.
   *
   * Setting this to true means that any errors which occur should be treated
   * as login errors, _even if there's a user saved in the session!_.
   */
  readonly isLogin?: true;
}

export interface IOAuthAuthorizeClient {
  authorize(options?: IOAuthAuthorizeOptions): Promise<never>;
  handleResponse(): Promise<PkceResponseContext | undefined>;
}

/**
 * Get a client which makes calls to the OAuth2 `/authorize` endpoint.
 */
export function getOAuthAuthorizeClient(_options: IOAuthAuthorizeClientOptions): IOAuthAuthorizeClient {
  //
  // Public
  //

  async function authorize(options: IOAuthAuthorizeOptions = {}): Promise<never> {
    const {
      tenantId,
      username,
      domain,
      consentScopes = [],
      accessScopes = [],
      prompt,
      restoreUri,
      extraQueryParameters = {},
      isLogin
    } = options;
    const { openIdConfigClient, store, manager } = _options;

    _logger.debug('authorizing', options);

    // Detect a reload loop if the page has loaded 10 or more times in the last
    // 30 seconds, and at least once within the last 5 seconds.
    if (_pageLoadStats.getCount(30) >= 10 && _pageLoadStats.getCount(5) >= 1) {
      // Reset the page load stats since we're throwing an error, so that the
      // the next time the page loads, the loop detection starts over. If we
      // don't do this, then if the user manually reloads, there may still
      // be too many recent reloads, causing the reload loop detection to be
      // sticky.
      _pageLoadStats.reset();

      throw new AuthError('unknown', 'reload loop detected');
    }

    return manager.setRedirect(async () => {
      const state = getUuid();
      const { verifier, challenge, challengeMethod } = await getPkceCodes();
      const url = await openIdConfigClient.getAuthorizeEndpoint({
        tenantId,
        consentScopes: [...new Set([...consentScopes, ...accessScopes])],
        challenge,
        challengeMethod,
        state,
        username,
        domain,
        prompt,
        extraQueryParameters
      });

      store.set(STORE_PKCE_REQUEST_CONTEXT_KEY, {
        state,
        verifier,
        restoreUri: restoreUri || window.location.href,
        tenantId,
        accessScopes,
        extraQueryParameters,
        isLogin
      });
      window.location.assign(url.href);
    });
  }

  function handleResponse(): Promise<PkceResponseContext | undefined> {
    _logger.debug('detecting auth redirect');

    const { store } = _options;
    const response = _removeResponse();
    const requestContext = store.remove(STORE_PKCE_REQUEST_CONTEXT_KEY);
    const responseContext = store.remove(STORE_PKCE_RESPONSE_CONTEXT_KEY);

    if (requestContext != null) {
      if (response != null) {
        _logger.debug('redirect in progress');
        return _handleRedirect(requestContext, response);
      } else {
        _logger.warn('redirect interrupted (no redirect response)');
      }
    } else if (response != null) {
      _logger.warn('unsolicited login attempt (no redirect in progress)');
    } else if (responseContext != null) {
      _logger.debug('redirect complete');
    } else {
      _logger.debug('no redirect in progress');
    }

    return Promise.resolve(responseContext);
  }

  //
  // Private
  //

  const _logger = _options.loggerFactory.getLogger('AuthV1Authorize');
  const _pageLoadStats = getPageLoadStats();

  /**
   * Match key/value pairs (e.g. `key0=value&key1=value`) in a string _very
   * loosely_.
   *
   * The string should look _similar_ to a query string. However, anything
   * that even _looks_ like a key and value will be matched. A key can contain
   * any word (RegExp `\w`) character, and it must be followed by an equal
   * (`=`) character. The value is then zero or more characters following the
   * equal character until the next ampersand (`&`) or the end of the string.
   * Anything that doesn't match that pattern is silently skipped.
   *
   * + _All values will be URI decoded (including replacing `+` characters
   * with spaces)._
   * + _The last value will be taken for duplicate keys._
   */
  function _getQueryishParams(str: string): Record<string, string | undefined> {
    const params: Record<string, string> = {};
    const rx = /(\w+)=([^&]*)/g;

    for (let match = rx.exec(str); match != null; match = rx.exec(str)) {
      const [, key, value] = match;
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    }

    return params;
  }

  function _removeResponse(): PkceResponse | undefined {
    const { code, state, error, ...other } = _getQueryishParams(pageLoadHash);

    if (code != null && pageLoadHash === window.location.hash) {
      // If it even _looks_ like the hash might contain an auth code, then
      // remove it immediately.
      window.history.replaceState(
        window.history.state,
        window.document.title,
        `${window.location.origin}${window.location.pathname}${window.location.search}`
      );
    }

    if (error != null) {
      return { error, ...other };
    } else if (code) {
      return { code, state };
    }

    return;
  }

  function _handleRedirect(requestContext: PkceRequestContext, response: PkceResponse): Promise<never> {
    const { store, manager } = _options;
    const responseContext = _getResponseContext(requestContext, response);

    _logger.debug(`redirect result`, getCleanRecord({ ...responseContext, code: undefined, verifier: undefined }));
    _logger.debug(`restoring URI: ${requestContext.restoreUri}`);
    store.set(STORE_PKCE_RESPONSE_CONTEXT_KEY, responseContext);

    return manager.setRedirect(() => {
      // Intentionally done this way to force a reload even if only the hash changes, which normalizes the login flow
      // to always include a url restoration redirect.
      window.history.replaceState(window.history.state, window.document.title, requestContext.restoreUri);
      window.location.reload();
    });
  }

  function _getResponseContext(requestContext: PkceRequestContext, response: PkceResponse): PkceResponseContext {
    const { tenantId, accessScopes, extraQueryParameters, isLogin, verifier, state: savedState } = requestContext;

    if ('error' in response) {
      const { error, error_description, correlation_id, ...other } = response;

      return {
        tenantId,
        accessScopes,
        extraQueryParameters,
        isLogin,
        error,
        errorDescription: error_description,
        correlationId: correlation_id,
        other
      };
    }

    const { code, state } = response;

    if (savedState !== state) {
      _logger.warn('redirect state mismatch');

      return {
        tenantId,
        accessScopes,
        extraQueryParameters,
        isLogin,
        error: 'server',
        errorDescription: 'redirect state does not match saved state',
        correlationId: undefined,
        other: {
          savedState,
          state: state ?? ''
        }
      };
    }

    return { tenantId, accessScopes, extraQueryParameters, isLogin, code, verifier };
  }

  return { authorize, handleResponse };
}
