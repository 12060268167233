import { IRequestInit } from './IRequestInit';
import { IResponse } from './IResponse';
import { HeadersPolyfill } from './HeadersPolyfill';

export type SimpleFetch = (url: string, init?: IRequestInit) => Promise<IResponse>;

let fetch: SimpleFetch;

if ('fetch' in window && 'AbortSignal' in window && 'AbortController' in window) {
  fetch = (url, { signal: simpleSignal, ...options } = {}) => {
    let signal: AbortSignal | null = null;

    if (simpleSignal instanceof window.AbortSignal) {
      signal = simpleSignal;
    } else if (simpleSignal != null) {
      const controller = new window.AbortController();

      signal = controller.signal;

      if (simpleSignal.aborted) {
        controller.abort();
      } else {
        simpleSignal.addEventListener('abort', () => controller.abort());
      }
    }

    return window.fetch(url, { signal, ...options });
  };
} else if ('XMLHttpRequest' in window) {
  fetch = (url, { method = 'GET', headers = {}, body, signal } = {}) =>
    new Promise((resolve, reject) => {
      const xhr = new window.XMLHttpRequest();

      xhr.addEventListener('load', () => {
        const blob = xhr.response as Blob;
        const text = () =>
          new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              if (typeof reader.result === 'string') {
                resolve(reader.result as string);
              } else {
                reject(Error('failed to read blob as text'));
              }
            };
            reader.onerror = () => reject(new Error('failed to read blob as text'));
            reader.readAsText(blob);
          });

        resolve({
          ok: Math.floor(xhr.status / 100) === 2,
          status: xhr.status,
          headers: new HeadersPolyfill(xhr.getAllResponseHeaders()),
          blob: async () => blob,
          text,
          json: () => text().then(text => JSON.parse(text))
        });
      });
      xhr.addEventListener('abort', () => {
        reject(Error('request aborted'));
      });
      xhr.addEventListener('error', () => {
        reject(Error('request failed'));
      });
      xhr.open(method.toUpperCase(), url);
      xhr.responseType = 'blob';

      new HeadersPolyfill(headers).forEach((value: string, name: string) => xhr.setRequestHeader(name, value));

      xhr.send(body);

      if (signal?.aborted) {
        xhr.abort();
      } else {
        signal?.addEventListener('abort', () => xhr.abort());
      }
    });
} else {
  fetch = () => {
    throw Error('no request implementation available (missing: fetch, XMLHttpRequest)');
  };
}

export { fetch };
