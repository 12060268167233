import { eqAny, Static } from '../../utils/Types';

export const isAuthErrorCause = eqAny([
  'configuration',
  'client',
  'server',
  'transient',
  'interaction_required',
  'unknown'
] as const);

/**
 * - __configuration__: The auth instance is misconfigured.
 * - __client__: The client did something wrong. There is no point in retrying.
 * - __server__: The server did something wrong. Retrying might help.
 * - __transient__: The server is overloaded. You should retry.
 * - __interaction_required__: The request can probably succeed if retried with user interaction.
 * - __unknown__: Something went wrong, but we're not sure what.
 */
export type AuthErrorCause = Static<typeof isAuthErrorCause>;
export type AuthErrorOther = Readonly<Record<string, unknown>>;

export interface IAuthErrorDetails {
  readonly correlationId?: string;
  readonly internalCode?: string;
  readonly internalName?: string;
  readonly internalError?: string;
  readonly other?: AuthErrorOther;
}

export interface IAuthErrorJson {
  readonly message: string;
  /**
   * - __configuration__: The auth instance is misconfigured.
   * - __client__: The client did something wrong. There is no point in retrying.
   * - __server__: The server did something wrong. Retrying might help.
   * - __transient__: The server is overloaded. You should retry.
   * - __interaction_required__: The request can probably succeed if retried with user interaction.
   * - __unknown__: Something went wrong, but we're not sure what.
   */
  readonly cause: AuthErrorCause;
  readonly details?: IAuthErrorDetails;
}

export interface IAuthError extends Required<IAuthErrorJson> {
  extendDetails(details: Partial<IAuthErrorDetails>): IAuthError;
  toJSON(): IAuthErrorJson;
}
