import { ILoggerHandler } from './ILoggerHandler';

export const DefaultLoggerHandler: ILoggerHandler = ({ level, name, message, data }) => {
  const args: any[] = [];
  const substitutions: string[] = [];

  if (message != null) {
    args.push(message);
    substitutions.push('%s');
  }

  if (data !== undefined) {
    args.push(data);
    substitutions.push('%o');
  }

  console.log(`%s [%s] ${substitutions.join('\n')}`, level.toString(), name, ...args);
};
