import { Account } from 'msal';

import { getAuthUser, IAuthUser } from '../../core';

/**
 * Map the MSAL `Account` object to an `IAuthUser` instance.
 */
export const getUser = (account: Account | null | undefined): IAuthUser | null => {
  if (account == null) {
    return null;
  }

  const { environment, name, userName, idTokenClaims } = account;

  return getAuthUser({
    objectId: [idTokenClaims.oid],
    username: [
      userName,
      idTokenClaims.preferred_username,
      idTokenClaims.unique_name,
      idTokenClaims.upn,
      idTokenClaims.email
    ],
    email: [idTokenClaims.email],
    name: [name, idTokenClaims.name],
    issuer: [environment, idTokenClaims.iss],
    identityProvider: [idTokenClaims.idp],
    tenantId: [idTokenClaims.tid],
    rawClaims: idTokenClaims
  });
};
