import { IAbortController } from './IAbortController';
import { AbortSignalPolyfill } from './AbortSignalPolyfill';

export class AbortControllerPolyfill implements IAbortController {
  public readonly signal = new AbortSignalPolyfill();

  public abort(): void {
    this.signal._aborted = true;
    [...this.signal._listeners].forEach(listener => listener());
  }
}
