import { getUuid } from './getUuid';

export const SESSION_ID_KEY = '@iamexperiences/session-id';

export function getSessionId(): string {
  let sessionId = window.sessionStorage.getItem(SESSION_ID_KEY);

  if (sessionId == null) {
    sessionId = getUuid();
    window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
  }

  return sessionId;
}
