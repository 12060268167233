import { getCryptoMock } from './getCryptoMock';
import { ICrypto } from './ICrypto';

let instance: ICrypto | undefined;

export const getCrypto = (): ICrypto => {
  if (instance == null) {
    if ('msCrypto' in window) {
      instance = (window as any).msCrypto as ICrypto;
    } else if ('crypto' in window) {
      instance = window.crypto;
    } else {
      console.warn('using crypto mock (browser crypto API not available)');
      instance = getCryptoMock();
    }
  }

  return instance;
};
