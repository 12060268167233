import { getBestUsername } from './getBestUsername';

/**
 * Find the best "email". If the value looks like an email, return it.
 * Otherwise return undefined. The returned value will either look like an
 * email, or be undefined.
 */
export function getBestEmail(values: string[]): string | undefined {
  const [result] = getBestUsername(values);

  return result != null && result.indexOf('@') >= 0 ? result : undefined;
}
