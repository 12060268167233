/**
 * Deep compare primitives, object literals, and arrays. _Only compares class
 * instances by reference!_
 */
export function isDeepEqual(a: any, b: any): boolean {
  if (a === b) return true;
  if (typeof a !== 'object' || typeof b !== 'object') return false;
  if (a === null || b === null) return false;
  if (Object.getPrototypeOf(a).constructor !== Object || Object.getPrototypeOf(b).constructor !== Object) {
    // Instances, not object literals.
    return false;
  }

  if (a instanceof Array) {
    if (!(b instanceof Array)) return false;
    if (a.length !== b.length) return false;

    return !a.some((value, i) => !isDeepEqual(value, b[i]));
  }

  const keysA = Object.keys(a);
  const keysB = new Set(Object.keys(b));

  if (keysA.length !== keysB.size) return false;
  if (!keysA.some(key => keysB.has(key))) return false;

  return !keysA.some(key => !isDeepEqual(a[key], b[key]));
}
