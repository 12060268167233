import { getNormalPromptQuery } from './getNormalPromptQuery';
import { getNormalUsername } from './getNormalUsername';
import { AuthAutoLoginHandler } from './types';
import { getArray } from '../utils/Types';

export interface IAuthAutoLoginHandlerOptions {
  readonly usernameParam?: string | readonly string[];
  readonly tenantIdParam?: string | readonly string[];
  readonly domainParam?: string | readonly string[];
  readonly promptParam?: string | readonly string[];
}

export function getAutoLoginHandler({
  usernameParam = ['upn', 'login_hint'],
  tenantIdParam = ['tid', 'tenant', 'tenantId'],
  domainParam = ['domain', 'domain_hint'],
  promptParam = ['prompt']
}: IAuthAutoLoginHandlerOptions = {}): AuthAutoLoginHandler {
  return (href: string) => {
    const url = new URL(href);
    const userNameParams = getArray(usernameParam);
    const tenantIdParams = getArray(tenantIdParam);
    const domainParams = getArray(domainParam);
    const promptParams = getArray(promptParam);

    const username = getNormalUsername(_findParamValue(url, userNameParams));
    const tenantId = _findParamValue(url, tenantIdParams);
    const domain = _findParamValue(url, domainParams);
    const prompt = getNormalPromptQuery(_findParamValue(url, promptParams));

    if (!username && !tenantId && !domain && !prompt) {
      return null;
    }

    [...userNameParams, ...tenantIdParams, ...domainParams, ...promptParams].forEach(param =>
      url.searchParams.delete(param)
    );

    return {
      hints: { username, tenantId, domain, prompt },
      url: url.href
    };
  };
}

export const defaultAutoLoginHandler = getAutoLoginHandler();

function _findParamValue(url: URL, params: readonly string[]): string | undefined {
  return [...url.searchParams.entries()].find(([key]) => params.includes(key))?.[1];
}
