import { IStore } from './IStore';
import { IStoreOptions, StorePrefix } from './IStoreOptions';
import { IStoreKey, StoreKeyType } from './IStoreKey';
import { IStorage } from './IStorage';

/**
 * Persistent storage abstraction, which defaults to using the browsers
 * session storage.
 */
export function getStore(_options: StorePrefix | IStoreOptions): IStore {
  const { prefix: _prefix, storage: _storage = window.sessionStorage } =
    typeof _options === 'object' && 'prefix' in _options ? _options : { prefix: _options };
  const _getKey = _getKeyFunction(_prefix);

  function set<T extends IStoreKey<any>>(key: T, value: StoreKeyType<T>): StoreKeyType<T> {
    if (value === undefined) {
      remove(key);
    } else if (key.guard(value)) {
      const enhancedKey = _getKey(typeof key === 'string' ? key : key.name);

      _storage.setItem(enhancedKey, JSON.stringify(value));
    }

    return value;
  }

  function get<T>(key: IStoreKey<T>): T | undefined {
    const enhancedKey = _getKey(key.name);
    return _get(_storage, enhancedKey, key.guard);
  }

  function remove<T>(key: IStoreKey<T>): T | undefined {
    const enhancedKey = _getKey(key.name);
    const value = _get(_storage, enhancedKey, key.guard);

    _storage.removeItem(enhancedKey);

    return value;
  }

  return { set, get, remove };
}

function _get<T>(_storage: IStorage, enhancedKey: string, guard: (value: any) => value is T): T | undefined {
  const rawValue = _storage.getItem(enhancedKey);

  if (rawValue == null) return undefined;

  try {
    const value = JSON.parse(rawValue);
    return guard(value) ? value : undefined;
  } catch {
    return undefined;
  }
}

function _getKeyFunction(prefix: StorePrefix): (key: string) => string {
  if (typeof prefix === 'string') {
    return key => `${prefix}❘${key}`;
  }

  if (typeof prefix === 'function') {
    return key => {
      const prefixedKey = prefix(key);
      return typeof prefixedKey === 'string' ? prefixedKey : prefixedKey.join('❘');
    };
  }

  return key => [...prefix, key].join('❘');
}
