import { array, number, Static } from '../utils/Types';

const MAX_SECONDS = 60;
const SESSION_KEY = '__PAGE_LOAD_STATS_CONTEXT__';

export interface IPageLoadStats {
  /**
   * Get the number of times the page has loaded in the given number of seconds.
   *
   * _Only the last 60 seconds of page loads are tracked, so the maximum useful
   * value for the seconds parameter is 60._
   */
  getCount(seconds: number): number;

  /**
   * Reset the page load history. Only takes effect after the next page load,
   * unless the immediate flag is set to true.
   */
  reset(immediate?: boolean): void;
}

export function getPageLoadStats(): IPageLoadStats {
  return {
    getCount: (seconds: number): number => {
      const now = Date.now();
      return _stats.filter(timestamp => now - timestamp <= seconds * 1000).length;
    },
    reset: (immediate = false) => {
      window.sessionStorage.removeItem(SESSION_KEY);

      if (immediate) {
        _stats.length = 0;
      }
    }
  };
}

const _isStats = array(number);

type Stats = Static<typeof _isStats>;

function _loadStats(): Stats {
  try {
    const item = window.sessionStorage.getItem(SESSION_KEY);
    const stats = item && JSON.parse(item);

    return _isStats(stats) ? stats : [];
  } catch {
    return [];
  }
}

const _now = Date.now();
const _stats = [..._loadStats(), Date.now()].filter(timestamp => _now - timestamp <= MAX_SECONDS * 1000);

window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(_stats));
