import { NonEmptyArray, Predicate } from './types';
import { Guard, GuardFn, Static } from './guard';

/**
 * Create a guard which matches arrays of unknown values.
 */
export function array(): Guard<unknown[]>;
/**
 * Create a guard which matches an array when all of its values match one of
 * the given type guards.
 *
 * ```ts
 * const isStringsAndNumbers: Guard<(string | number)[]> = array(typeOf('string' | 'number'))
 * ```
 */
export function array<TGuardFns extends NonEmptyArray<GuardFn<any>>>(...fns: TGuardFns): Guard<Static<TGuardFns>[]>;
export function array(...fns: Predicate[]): Predicate {
  return x => x instanceof Array && (fns.length === 0 || fns.some(fn => x.every(value => fn(value))));
}

/**
 * Match array of unknown values.
 */
export const isArray = array();
