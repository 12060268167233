import { getArray } from '../utils/Types';

/**
 * Normalizes zero or more resources which might be OAuth v1 audience URIs,
 * into OAuth v2 scopes.
 *
 * _This will never return an empty array!_ If the returned array would be
 * empty, then undefined will be returned instead.
 *
 * @param resource OAuth v1 audience or OAuth v2 scopes.
 */
export function getNormalScopes(resource: string | readonly string[] | undefined): [string, ...string[]] | undefined {
  if (resource == null) return undefined;

  const resources = [...getArray(resource)]
    .map(value => value.trim())
    .join(' ')
    .split(/\s+/)
    .filter(value => !!value)
    .map(_getNormalScope);

  return resources.length > 0 ? (resources as [string, ...string[]]) : undefined;
}

/**
 * Normalizes a resource which might be an OAuth v1 audience URI, into an
 * OAuth v2 scope.
 *
 * @param resource OAuth v1 audience or OAuth v2 scope.
 */
const _getNormalScope = (resource: string): string => {
  if (/^\w+:\/{2}/.test(resource)) {
    // Resource looks like a URI.
    if (!/^\w+:\/{2}[^?#]*\/[^/?#.]*\.[^/?#]+$/.test(resource)) {
      // The URI doesn't end with something that looks like a scope
      // (e.g. /.default, /user.read, /.default+user.read, etc.).
      return resource.replace(/\/?$/, '/.default');
    }
  } else if (/^[0-9a-f]{8}(\-[0-9a-f]{4}){3}\-[0-9a-f]{12}$/i.test(resource)) {
    // Resource looks like a UUID.
    return `${resource}/.default`;
  }

  return resource;
};
