import { Predicate, OneOrMore } from './types';
import { Guard, GuardFn, Static, guard } from './guard';
import { getArray } from './getArray';

/**
 * Create a guard which matches any object (including arrays, but not `null`
 * values). The guard type does _not_ have an index signature.
 *
 * ```ts
 * const obj: Guard<{}> = struct();
 * ```
 *
 * The returned guard has `required()` and `optional()` methods for defining
 * properties.
 *
 * ```ts
 * const value: Guard<{ a: string, b?: number }> = struct()
 *   .required({ a: typeOf('string') })
 *   .optional({ b: typeOf('number') });
 * ```
 */
export function struct(): StructGuard<{}> {
  return _extendStruct((x: any): x is {} => x != null && typeof x === 'object');
}

function _extendStruct<TGuardFn extends GuardFn<any>>(parentFn: TGuardFn): StructGuard<Static<TGuardFn>> {
  const parent = guard(parentFn);
  return Object.assign(parent, {
    required<TGuardFns extends StructGuardFns>(fns: TGuardFns) {
      const fnArr: [string, readonly Predicate[]][] = Object.keys(fns).map(key => [key, getArray(fns[key])]);
      return _extendStruct(
        parent.intersect((x: any): x is StructRequired<TGuardFns> =>
          fnArr.every(([key, keyFns]) => keyFns.some(fn => fn(x[key])))
        )
      );
    },
    optional<TGuardFns extends StructGuardFns>(fns: TGuardFns) {
      const fnArr: [string, readonly Predicate[]][] = Object.keys(fns).map(key => [key, getArray(fns[key])]);
      return _extendStruct(
        parent.intersect((x: any): x is StructOptional<TGuardFns> =>
          fnArr.every(([key, keyFns]) => x[key] === undefined || keyFns.some(fn => fn(x[key])))
        )
      );
    }
  });
}

type StructRequired<TGuardFns> = TGuardFns extends Record<string, OneOrMore<GuardFn<any>>>
  ? {
      [P in keyof TGuardFns]: Static<TGuardFns[P]>;
    }
  : never;
type StructOptional<TGuardFns> = TGuardFns extends Record<string, OneOrMore<GuardFn<any>>>
  ? {
      [P in keyof TGuardFns]?: Static<TGuardFns[P]>;
    }
  : never;
type StructGuardFns = Record<string, OneOrMore<GuardFn<any>>>;
type StructGuard<T> = Guard<T> & {
  /**
   * Add required properties to a `struct()` definition.
   *
   * ```ts
   * const value: Guard<{ a: string }> = struct().required({ a: typeOf('string') });
   * ```
   */
  required<TGuardFns extends StructGuardFns>(fns: TGuardFns): StructGuard<T & StructRequired<TGuardFns>>;
  /**
   * Add optional properties to a `struct()` definition.
   *
   * ```ts
   * const value: Guard<{ a?: string }> = struct().optional({ a: typeOf('string') });
   * ```
   */
  optional<TGuardFns extends StructGuardFns>(fns: TGuardFns): StructGuard<T & StructOptional<TGuardFns>>;
};
