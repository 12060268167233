import React, { useEffect, useRef } from 'react';

import { useAuthContext } from './AuthHooks';
import { IAuthLoginHints } from '../core';

export interface IWithUserProps {
  /**
   * If true and no user is logged in, the login flow will be started when the
   * component is rendered.
   */
  login?: boolean | IAuthLoginHints;
}

/**
 * This component does not render its children unless a user is logged in.
 *
 * Set the `login` property to `true` or an `IAuthLoginHints` object, to
 * auto-trigger the login flow when the component is rendered and no user is
 * logged in.
 *
 * __Note: This component will throw login errors if the `login` property is
 * set to anything except `false`. The error can be caught by an error
 * boundary. This is to avoid potential login loops.__
 */
export const WithUser: React.FC<IWithUserProps> = ({ login = false, children }) => {
  const { auth, isReady, user, error } = useAuthContext();
  const isLoginCalled = useRef(false);

  useEffect(() => {
    if (login !== false && isReady && user == null) {
      if (error != null) {
        throw error;
      } else if (!isLoginCalled.current) {
        isLoginCalled.current = true;
        auth.login(typeof login === 'boolean' ? undefined : login);
      }
    }
  }, [login, auth, isReady, user, error]);

  return isReady && user != null ? <>{children}</> : null;
};
