import { AuthLoginPrompt } from './types';

export function getNormalPromptQuery(prompt: string | undefined): AuthLoginPrompt | undefined {
  if (prompt == null) {
    return;
  }

  switch (prompt.toLowerCase()) {
    case 'select_account':
    case 'account':
      return 'account';
    case 'login':
    case 'credentials':
      return 'credentials';
  }

  return;
}
