import { IStorage } from './IStorage';

const _map = new Map<string, string>();

export function getMemoryStorage(): IStorage {
  return {
    getItem: key => _map.get(key) ?? null,
    setItem: (key, value) => _map.set(key, value),
    removeItem: key => {
      _map.delete(key);
    }
  };
}
