import React from 'react';

import { useAuthContext } from './AuthHooks';

/**
 * This component does not render its children unless the auth context is ready
 * and no user is logged in.
 */
export const WithoutUser: React.FC = ({ children }) => {
  const { isReady, user } = useAuthContext();

  if (isReady && user == null) {
    return <>{children}</>;
  }

  return null;
};
