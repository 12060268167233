export const GUEST_USERNAME_REGEX = /^(.+?)(?:_([^_]*?))?\d*#EXT#@/;
export const AUTHORITY_URI_REGEX = /^https?:\/{2}[^\/]+\/([0-9a-f]{8}(\-[0-9a-f]{4}){3}\-[0-9a-f]{12})(\/|$)/i;

/**
 * OpenID Connect _access_ scopes supported by AAD. This is the same as
 * `OIDC_SCOPES`, minus `offline_access` (which doesn't come back in access
 * tokens).
 */
export const OIDC_ACCESS_SCOPES = ['openid', 'profile', 'email'] as const;

/**
 * OpenID Connect scopes supported by AAD. This is the same as
 * `OIDC_ACCESS_SCOPES`, plus `offline_access`.
 */
export const OIDC_SCOPES = [...OIDC_ACCESS_SCOPES, 'offline_access'] as const;
