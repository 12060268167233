import { AuthError as MsalAuthError } from 'msal';

import { AuthError, AuthErrorCause } from '../../core';

export const getAuthError = (err: any): AuthError => {
  if (err == null) return new AuthError('unknown', 'Unknown error.');
  if (err instanceof AuthError) return err;

  let cause: AuthErrorCause = 'unknown';
  let correlationId: string | undefined;
  let internalCode: string | undefined;
  let other: Record<string, any> | undefined;

  if (err instanceof MsalAuthError) {
    internalCode = err.errorCode;

    switch (err.name) {
      case 'ClientAuthError':
        cause = 'client';
        break;
      case 'ClientConfigurationError':
        cause = 'configuration';
        break;
      case 'ServerError':
        cause = 'server';
        break;
      case 'InteractionRequiredAuthError':
        cause = 'interaction_required';
        break;
    }

    const regex = /^[\s\r\n]*([^:=\r\n]+?)\s*[:=]\s*(.*?)[\s\r\n]*$/gm;
    let match: RegExpMatchArray | null;

    while (null != (match = regex.exec(err.message))) {
      const [, key, value] = match;

      if (other == null) {
        other = {};
      }

      other[key.toLowerCase().replace(/\s+(\S)/g, (_match: string, c: string) => c.toUpperCase())] = value;
    }

    if (other?.correlationId != null) {
      correlationId = `${other.correlationId}`;
      delete other.correlationId;
    }
  }

  return new AuthError(cause, err, {
    correlationId,
    internalCode,
    internalName: err instanceof Error ? err.name : undefined,
    internalError: `${err?.stack ?? err}`,
    other
  });
};
