import { getDefaultLoggerFactory } from './getDefaultLoggerFactory';
import { AuthError } from '../core';
import { IAuthUser, IAuthManagerOptions, IAuthManager, IAuthState } from './types';
import { getSubject } from '../utils/Subject';
import { isDeepEqual, eq, typeOf } from '../utils/Types';
import { getStoreKey } from '../utils/Store';

const STORE_SAVED_LOGIN_ERROR_KEY = getStoreKey('saved-login-error', typeOf('string'));
const STORE_SAVED_TOKEN_ERROR_KEY = getStoreKey('saved-token-error', typeOf('string'));
// Milliseconds to wait after a login/token redirect before assuming that the
// redirect is not going to happen for some reason.
const REDIRECT_TIMEOUT = 5_000;

export function getAuthManager({
  clientId: _clientId,
  authority: _authority,
  store: _store,
  loggerFactory: _loggerFactory = getDefaultLoggerFactory(),
  cacheErrors: _cacheErrors = false
}: IAuthManagerOptions): IAuthManager {
  //
  // Public
  //

  function init(): void {
    _logger.debug('init');

    try {
      _initialized = true;

      _tokenError.subscribe(_onTokenError, false);
      _loginError.subscribe(_onLoginError, false);
      _user.subscribe(_onStateChange, false);
      _isReady.subscribe(_onStateChange, false);
      _isRedirecting.subscribe(_onStateChange, false);
      _isInteractive.subscribe(_onInteractive, false);

      // Load cached token error.
      const tokenError = _store.remove(STORE_SAVED_TOKEN_ERROR_KEY);
      _tokenError.next(tokenError != null ? AuthError.rehydrate(tokenError) : null);

      // Load cached login error.
      const loginError = _store.remove(STORE_SAVED_LOGIN_ERROR_KEY);
      _loginError.next(loginError != null ? AuthError.rehydrate(loginError) : null);
    } catch (err) {
      return _logger.throwError(
        new AuthError('configuration', 'init failed', {
          internalName: err instanceof Error ? err.name : undefined,
          internalError: `${err}`
        })
      );
    }
  }

  function assertInitialized(): void {
    if (!_initialized) {
      _logger.throwError(new AuthError('configuration', 'not initialized'));
    }
  }

  function setUser(user: IAuthUser): void {
    assertInitialized();

    _logger.debug('set user', user);
    _tokenError.next(null);
    _user.next(user);
  }

  function setReady(): void {
    assertInitialized();
    _logger.debug(`set ready`);
    // Update the ready state asynchronously (next tick) to "debounce" it when
    // extra login redirects happen. Auth implementations have a bad habit of
    // redirecting again right after handling the auth flow login redirect.
    window.setTimeout(() => {
      _logger.debug(`is ready`);
      _isReady.next(true);
    });
  }

  function setTokenError(err: unknown): void {
    assertInitialized();

    if (err == null) {
      _logger.debug('clear token error');
      _tokenError.next(null);
    } else {
      const authError = err instanceof AuthError ? err : new AuthError('unknown', err);

      _logger.error('set token error', authError);
      _tokenError.next(authError);
    }
  }

  function setLoginError(err: unknown): void {
    assertInitialized();

    if (err == null) {
      _logger.debug('clear login error');
      _loginError.next(null);
    } else {
      const authError = err instanceof AuthError ? err : new AuthError('unknown', err);

      _logger.error('set login error', authError);
      _loginError.next(authError);
      _tokenError.next(null);
    }
  }

  function clearSession(): void {
    assertInitialized();

    _logger.debug('clear session');
    _tokenError.next(null);
    _loginError.next(null);
  }

  function setRedirect(cb: () => void | Promise<void>): Promise<never> {
    assertInitialized();

    _logger.debug('set redirect');
    // Even though this function returns a promise, it's important that this
    // flag be set synchronously.
    _isRedirecting.next(true);

    let caught: any;

    if (_redirectPromise != null) {
      _logger.debug('waiting on previous redirect');
    }

    const promise = (_redirectPromise = (_redirectPromise ?? Promise.resolve())
      .then(cb)
      .then(
        () =>
          // Clear the redirect after a timeout in case something goes wrong.
          new Promise<void>(resolve => {
            setTimeout(() => {
              _logger.warn('redirect timed out');
              resolve();
            }, REDIRECT_TIMEOUT);
          })
      )
      .catch(err => {
        // Save the error and allow this promise to be resolved without error,
        // so that any pending redirects get a chance to proceed.
        caught = err;
      }));

    return _redirectPromise
      .finally(() => {
        if (promise === _redirectPromise) {
          // There are no pending redirects, so clear the redirecting state.
          _logger.debug('unset redirect');
          _isRedirecting.next(false);
          _redirectPromise = undefined;
        } else {
          // There is a pending redirect, so don't clear the redirecting,
          // because redirecting is necessarily still in progress.
          _logger.debug('next redirect');
        }
      })
      .then(() => {
        // Now either throw the caught error, or return a promise that never
        // resolves. Because from the caller's perspective, a redirect should
        // be terminal. No further action should be taken unless an error
        // occurs in the callback, which would indicate the redirect is was
        // not successfully initiated.
        if (caught != null) {
          return Promise.reject(caught);
        } else {
          return new Promise<never>(() => undefined);
        }
      });
  }

  //
  // Private
  //

  const _logger = _loggerFactory.getLogger('AuthManager');

  const _isReady = getSubject<boolean>(false);
  const _user = getSubject<IAuthUser | null>(null, {
    shouldNotify: (value, lastValue) => !isDeepEqual(value, lastValue)
  });
  const _isRedirecting = getSubject<boolean>(false);
  const _isInteractive = getSubject<boolean>(false);
  const _isInteractiveUser = getSubject<boolean>(false);
  const _tokenError = getSubject<AuthError | null>(null);
  const _loginError = getSubject<AuthError | null>(null);

  const _state: IAuthState = {
    clientId: _clientId,
    authority: _authority,
    user: _user.readonly,
    isReady: _isReady.readonly,
    isRedirecting: _isRedirecting.readonly,
    isInteractive: _isInteractive.readonly,
    isInteractiveUser: _isInteractiveUser.readonly,
    tokenError: _tokenError.readonly,
    loginError: _loginError.readonly,
    waitForInteractive: _waitForInteractive,
    waitForInteractiveUser: _waitForInteractiveUser
  };

  let _initialized = false;
  let _redirectPromise: Promise<void> | undefined;
  let _errorCacheTimeout: number | undefined;

  function _waitForInteractive(): Promise<void> {
    return _isInteractive.waitFor(eq(true)).then(() => undefined);
  }

  function _waitForInteractiveUser(): Promise<void> {
    return _isInteractiveUser.waitFor(eq(true)).then(() => undefined);
  }

  function _onTokenError(): void {
    if (_cacheErrors) {
      const value = _tokenError();

      if (value == null) {
        _store.remove(STORE_SAVED_TOKEN_ERROR_KEY);
      } else {
        _store.set(STORE_SAVED_TOKEN_ERROR_KEY, JSON.stringify(value));
      }

      _resetErrorCacheTimeout();
    }
  }

  function _onLoginError(): void {
    if (_cacheErrors) {
      const value = _loginError();

      if (value == null) {
        _store.remove(STORE_SAVED_LOGIN_ERROR_KEY);
      } else {
        _store.set(STORE_SAVED_LOGIN_ERROR_KEY, JSON.stringify(value));
      }

      _resetErrorCacheTimeout();
    }
  }

  function _onStateChange(): void {
    const ready = _isReady();
    const redirecting = _isRedirecting();
    const user = _user();

    _isInteractive.next(ready && !redirecting);
    _isInteractiveUser.next(_isInteractive() && user != null);
  }

  function _onInteractive(interactive: boolean): void {
    if (interactive) {
      _logger.info('interactive');
    } else {
      _logger.debug('not interactive');
    }
  }

  function _resetErrorCacheTimeout(): void {
    _logger.debug('cache timeout reset');
    clearTimeout(_errorCacheTimeout);

    _errorCacheTimeout = setTimeout(() => {
      _logger.debug('cache timeout expired');
      _errorCacheTimeout = undefined;
      _store.remove(STORE_SAVED_LOGIN_ERROR_KEY);
      _store.remove(STORE_SAVED_TOKEN_ERROR_KEY);
    }, REDIRECT_TIMEOUT);
  }

  return {
    state: _state,
    init,
    assertInitialized,
    setUser,
    setReady,
    setLoginError,
    setTokenError,
    setRedirect,
    clearSession
  };
}
