import { getCleanRecord, isDictionary } from '../../utils/Types';
import { IAuthError, AuthErrorCause, IAuthErrorDetails, IAuthErrorJson, isAuthErrorCause } from '../types';

/**
 * Error type for all auth errors.
 */
export class AuthError extends Error implements IAuthError {
  #details: IAuthErrorDetails;

  /**
   * The high level cause of the error.
   */
  public readonly cause: AuthErrorCause;

  /**
   * Additional error details.
   */
  public get details(): IAuthErrorDetails {
    return this.#details;
  }

  public constructor();
  public constructor(cause: AuthErrorCause, message?: any, details?: Partial<IAuthErrorDetails>);
  public constructor(cause: AuthErrorCause = 'unknown', message?: any, details: Partial<IAuthErrorDetails> = {}) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    super(`${message?.message || message || details?.internalCode || cause}`);

    this.cause = cause;
    this.#details = getCleanRecord({
      ...details,
      other: { ...(details.other ?? {}) }
    });
  }

  public extendDetails(details: Partial<IAuthErrorDetails>): AuthError {
    this.#details = {
      ...details,
      ...this.#details,
      other: {
        ...(details.other ?? {}),
        ...this.#details.other
      }
    };

    return this;
  }

  public toJSON(): IAuthErrorJson {
    return {
      message: `${this.message}`,
      cause: isAuthErrorCause(this.cause) ? this.cause : 'unknown',
      details: getCleanRecord({
        correlationId: this.details.correlationId && `${this.details.correlationId}`,
        internalCode: this.details.internalCode && `${this.details.internalCode}`,
        internalName: this.details.internalName && `${this.details.internalName}`,
        internalError: this.details.internalError && `${this.details.internalError}`,
        other: this.details.other
      })
    };
  }

  public static rehydrate(source: unknown): AuthError {
    if (source instanceof AuthError) {
      return source;
    }

    let value: unknown;

    if (typeof source === 'string') {
      try {
        value = JSON.parse(source);
      } catch (err) {
        return new AuthError('client', 'failed parsing auth error JSON string');
      }
    } else {
      value = source;
    }

    if (!isDictionary(value)) {
      return new AuthError('client', 'invalid auth error JSON data');
    }

    const cause = isAuthErrorCause(value.cause) ? value.cause : 'unknown';
    const details = isDictionary(value.details) ? value.details : {};
    const correlationId = typeof details.correlationId === 'string' ? details.correlationId : undefined;
    const internalCode = typeof details.internalCode === 'string' ? details.internalCode : undefined;
    const internalName = typeof details.internalName === 'string' ? details.internalName : undefined;
    const internalError = typeof details.internalError === 'string' ? details.internalError : undefined;
    const other = isDictionary(details.other) ? details.other : {};

    return new AuthError(
      cause,
      value.message,
      getCleanRecord({
        correlationId,
        internalCode,
        internalName,
        internalError,
        other
      })
    );
  }
}
