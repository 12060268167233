import { getDefaultLoggerFactory } from './getDefaultLoggerFactory';
import { AuthError } from '../core';
import { IAuthManager, IAuth, IAuthLoginHints, IAuthTokenHints, IAuthLogoutHints, IAuthInitOptions } from './types';
import { once } from '../utils/Decorators';
import { getCleanRecord, typeOf } from '../utils/Types';
import { ILoggerFactory } from '../utils/Logger';
import { getStoreKey, IStore } from '../utils/Store';

const STORE_LOGIN_HINTS_KEY = getStoreKey('saved-login-hints', typeOf('string'));

/**
 * Instance decorator for `IAuth` implementations which enforces standard
 * lifecycle behavior for auth methods.
 *
 * Should be called in IAuth implementation the constructor.
 *
 * __Features:__
 * + `init()`
 *   + Bound.
 *   + Skip if already called.
 *   + Call `manager.init()` automatically.
 * before `init()`.
 * + `login()`
 *   + Bound.
 *   + Skip if a user is logged in that matches the login hints.
 *   + Clear login error when called.
 *   + Set login error on throw (sync only).
 *   + Throw error if called before `init()`.
 * + `acquireToken()`
 *   + Bound.
 *   + Clear token error when called.
 *   + Set token error on throw (sync or async).
 *   + Throw error if called before `init()`.
 * + `logout()`
 *   + Bound.
 *   + Clear session when called.
 *   + Throw error if called before `init()`.
 */
export function withAuthLifecycle<T extends Pick<IAuth, 'init' | 'login' | 'acquireToken' | 'logout'>>(
  target: T,
  manager: IAuthManager,
  loggerFactory: ILoggerFactory = getDefaultLoggerFactory(),
  store: IStore
): T {
  const logger = loggerFactory.getLogger('AuthLifecycle');
  const { init, login, logout, acquireToken } = target;
  const tokenPromises = new Map<string, Promise<string>>();

  target.init = once((options?: IAuthInitOptions): void => {
    logger.info('init');
    manager.init();

    try {
      init.call(target, options);
    } catch (err) {
      logger.throwError(
        new AuthError('configuration', 'init failed', {
          internalName: err instanceof Error ? err.name : undefined,
          internalError: `${err}`
        })
      );
    }
  });

  target.login = (hints: IAuthLoginHints = {}): void => {
    manager.assertInitialized();

    logger.debug('login called');

    if (!manager.state.isInteractive()) {
      logger.debug('login skipped due to non-interactive state');
      return;
    }

    const user = manager.state.user();
    const { force, ...hintsKey } = hints;
    const currentHintsString = JSON.stringify(getCleanRecord(hintsKey));
    const previousHintsString = store.get(STORE_LOGIN_HINTS_KEY);

    if (force !== true && user != null && currentHintsString === previousHintsString) {
      logger.info('login skipped because the hints are identical to the hints used to login the current user');
      return;
    }

    store.set(STORE_LOGIN_HINTS_KEY, currentHintsString);

    try {
      logger.info(`login (hints: ${JSON.stringify(hints)})`);
      manager.setLoginError(null);
      login.call(target, hints);
    } catch (err) {
      manager.setLoginError(err);
      throw manager.state.loginError();
    }
  };

  target.logout = (hints?: IAuthLogoutHints): void => {
    manager.assertInitialized();

    logger.debug('logout called');

    if (!manager.state.isInteractive()) {
      logger.debug('logout skipped due to non-interactive state');
    } else {
      logger.info(`logout (hints: ${JSON.stringify(hints ?? {})})`);
      manager.clearSession();
      store.remove(STORE_LOGIN_HINTS_KEY);
      logout.call(target, hints);
    }
  };

  target.acquireToken = async (resource?: string | readonly string[], hints: IAuthTokenHints = {}): Promise<string> => {
    logger.debug('acquire token called');

    if (!manager.state.isInteractiveUser()) {
      logger.debug('acquire token waiting for interactive user state');
      await manager.state.waitForInteractiveUser();
    }

    logger.info(`acquire token (resource: ${resource ?? ''}, hints: ${JSON.stringify(hints ?? {})})`);

    const promiseKey = JSON.stringify({ resource, hints });
    let promise = tokenPromises.get(promiseKey);

    if (promise == null) {
      logger.debug('non-duplicate acquire token request');
      promise = (async () => {
        try {
          manager.setTokenError(null);
          return await acquireToken.call(target, resource, hints);
        } catch (err) {
          manager.setTokenError(err);
          throw manager.state.tokenError();
        }
      })().finally(() => tokenPromises.delete(promiseKey));
      tokenPromises.set(promiseKey, promise);
    } else {
      logger.debug('duplicate acquire token request (merging)');
    }

    const { errorHandling } = hints;

    if (errorHandling === 'empty') {
      promise = promise.catch(() => '');
    } else if (errorHandling === 'never') {
      promise = promise.catch(() => new Promise<never>(() => undefined));
    }

    return promise;
  };

  return target;
}
