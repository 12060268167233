// TODO: Move to EndUserExperiences library.

import { NonEmptyArray, ItemType } from './types';

/**
 * Derive the type of a type guard.
 *
 * ```ts
 * const isMyType: Guard<{ a: string }> = struct().required({ a: typeOf('string') });
 * type MyType = Static<typeof isMyType>; // { a: string }
 * ```
 */
export type Static<T> = ItemType<T> extends (x: any) => x is infer R ? R : T;

/**
 * Simple type guard function (`(x: any) => x is T`)/
 */
export type GuardFn<T> = (x: any) => x is T;

/**
 * Type guard (`<T>(x: any) => x is T`) with an added `intersect()` method.
 */
export type Guard<T> = GuardFn<T> & {
  /**
   * Intersect the receiver (`Guard<X>`) with the target (`GuardFn<Y>`) to
   * create a _new_ type guard (`Guard<X & Y>`).
   *
   * __Example:__
   * ```ts
   * const x: Guard<X>;
   * const y: Guard<Y>;
   * const xy: Guard<X & Y> = x.intersect(y);
   * ```
   */
  intersect<TGuardFn extends GuardFn<any>>(fn: TGuardFn): Guard<T & Static<TGuardFn>>;
};

/**
 * Create a `Guard<T>` instance from a simple type guard function
 * (`<T>(x: any) => x is T`).
 *
 * ```ts
 * const isError: Guard<Error> = guard((x: any): x is Error => x instanceof Error);
 * ```
 *
 * `Guard<T>` has the same callable prototype as the simple guard function,
 * and an additional `intersect()` method.
 *
 * ```ts
 * const isStatusError: Guard<Error & { status: number }> = isError.intersect(
 *   struct().required({ status: typeOf('number') })
 * );
 * ```
 */
export function guard<T>(sourceFn: GuardFn<T>): Guard<T> {
  return 'intersect' in sourceFn
    ? ((sourceFn as unknown) as Guard<T>)
    : Object.assign((x: any): x is T => sourceFn(x), {
        intersect<TGuardFn extends GuardFn<any>>(fn: TGuardFn): Guard<T & Static<TGuardFn>> {
          return guard((x: any): x is T & Static<TGuardFn> => sourceFn(x) && fn(x));
        }
      });
}

/**
 * Match anything (`Guard<any>`).
 */
export const any = guard((_x: any): _x is any => {
  return true;
});

/**
 * Match anything (`Guard<unknown>`).
 */
export const unknown = guard((_x: any): _x is unknown => {
  return true;
});

/**
 * Match `null` or `undefined`.
 */
export const nil = guard((x: any): x is null | undefined => {
  return x == null;
});

/**
 * Create a guard which matches any of two or more type guards.
 *
 * ```ts
 * const a: Guard<A>;
 * const b: Guard<B>;
 * const abc: Guard<A | B> = union(a, b);
 * ```
 */
export function union<TGuardFns extends NonEmptyArray<GuardFn<any>>>(...fns: TGuardFns): Guard<Static<TGuardFns>> {
  return guard((x: any): x is Static<TGuardFns> => fns.some(fn => fn(x)));
}

/**
 * Cast a guard's type (`Guard<T>`) to readonly (`Guard<Readonly<T>>`). The
 * guard is returned unmodified. This is just a casting shortcut.
 */
export function readonly<TGuard extends Guard<any>>(guard: TGuard): Guard<Readonly<Static<TGuard>>> {
  return guard;
}
