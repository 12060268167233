export class HeadersPolyfill implements Headers {
  private _map = new Map<string, string>();

  public constructor(source: Headers | Record<string, string> | string = {}) {
    if (typeof source === 'string') {
      source
        .split(/\r\n/)
        .map(header => header.match(/^([^:]+):\s*(.*)$/))
        .filter((match): match is RegExpMatchArray => match != null)
        .forEach(([name, value]) => this.append(name, value));
    } else if (typeof source.forEach === 'function') {
      const headers = source as Headers;
      headers.forEach((value: string, name: string) => this.append(name, value));
    } else {
      const record = source as Record<string, string>;
      Object.keys(record).forEach(name => {
        this.append(name, record[name]);
      });
    }
  }

  public append(name: string, value: string): void {
    name = name.toLowerCase();
    const current = this._map.get(name);
    this._map.set(name, current != null ? `${current}, ${value}` : value);
  }

  public delete(name: string): void {
    this._map.delete(name.toLowerCase());
  }

  public get(name: string): string | null {
    return this._map.get(name.toLowerCase()) ?? null;
  }

  public has(name: string): boolean {
    return this._map.has(name.toLowerCase());
  }

  public set(name: string, value: string): void {
    this._map.set(name.toLowerCase(), value);
  }

  public forEach(callbackfn: (value: string, key: string, parent: Headers) => void, thisArg?: any): void {
    this._map.forEach((value, key) => callbackfn.call(thisArg, value, key, this));
  }

  public entries(): IterableIterator<[string, string]> {
    return this._map.entries();
  }

  public keys(): IterableIterator<string> {
    return this._map.keys();
  }

  public values(): IterableIterator<string> {
    return this._map.values();
  }

  public [Symbol.iterator](): IterableIterator<[string, string]> {
    return this._map.entries();
  }
}
