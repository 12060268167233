import { OneOrMore, ItemType } from './types';
import { Guard, guard } from './guard';
import { getArray } from './getArray';

/**
 * Create a guard which matches any of one or more given types, defined by
 * `typeof` strings and/or class constructors.
 *
 * __Example:__
 * ```ts
 * const isNumber: Guard<number> = typeOf('number');
 * const isStringOrRegExp: Guard<string | RegExp> = typeOf(['string', RegExp]);
 * ```
 *
 * An optional predicate second argument can be give to narrow the match to
 * only a subset of the given types.
 *
 * ```ts
 * const isFooPrefixed: Guard<string> = typeOf('string', string => /^foo/.test(string));
 * ```
 */
export function typeOf<TTypeArgs extends OneOrMore<TypeArg>>(
  types: TTypeArgs,
  match?: (x: TypeOf<TTypeArgs>) => boolean
): Guard<TypeOf<TTypeArgs>> {
  const many = getArray(types);

  return guard(
    (x: any): x is TypeOf<TTypeArgs> =>
      many.some((type: TypeArg) => (typeof type === 'string' ? typeof x === type : x instanceof type)) &&
      (match == null || match(x))
  );
}

export const string = typeOf('string');
export const number = typeOf('number');
export const bigint = typeOf('bigint');
export const boolean = typeOf('boolean');
export const func = typeOf('function');
export const object = typeOf('object');
export const symbol = typeOf('symbol');
export const undef = typeOf('undefined');

type TypeArg =
  | (new (...args: any[]) => any)
  | 'undefined'
  | 'object'
  | 'boolean'
  | 'number'
  | 'bigint'
  | 'string'
  | 'symbol'
  | 'function';

type TypeDecode<TArg> = TArg extends new (...args: any[]) => any
  ? InstanceType<TArg>
  : TArg extends 'undefined'
  ? undefined
  : TArg extends 'object'
  ? null | Record<string, any>
  : TArg extends 'boolean'
  ? boolean
  : TArg extends 'number'
  ? number
  : TArg extends 'bigint'
  ? bigint
  : TArg extends 'string'
  ? string
  : TArg extends 'symbol'
  ? symbol
  : TArg extends 'function'
  ? (...args: any[]) => any
  : unknown;

type TypeOf<TArg> = TypeDecode<ItemType<TArg>>;
