import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext, IAuthContext } from './AuthContext';
import { IAuth, IAuthUser, IAuthError } from '../core';
import { ISubscription } from '../utils/Subject';

/**
 * Get the complete auth context including: the auth implementation, ready
 * state, current user, token error, and login error.
 *
 * Used internally by all other auth hooks. Throws an exception if used in a
 * component that is not a descendent of an `AuthProvider` component.
 *
 * _The React context's ready state is a slightly modified from the auth
 * implementation's actual ready state. Components that are rendered **after**
 * an auth token/login redirect is initiated, will be shown a **not ready**
 * state, even though the auth implementation is actually ready. This is to
 * prevent UI "flickering" (briefly rendering before a redirect) when a
 * redirect is initiated immediately after page load (e.g. When login query
 * parameters are present). If you need the unmodified ready state, you can use
 * `useContext(AuthContext).isReady`.
 */
export const useAuthContext = (): IAuthContext => {
  const context = useContext(AuthContext);
  const auth = context?.auth;
  const [isReady, setIsReady] = useState(() => auth != null && auth.isReady() && !auth.isRedirecting());
  const subscriptions = useRef<readonly ISubscription[]>([]);

  useEffect(() => {
    if (auth == null) return;

    const updateIsReady = () => {
      if (isReady) {
        if (!auth.isReady()) {
          setIsReady(false);
        }
      } else if (auth.isReady() && !auth.isRedirecting()) {
        setIsReady(true);
      }
    };

    subscriptions.current = [auth.isReady.subscribe(updateIsReady), auth.isRedirecting.subscribe(updateIsReady)];

    return () => subscriptions.current.forEach(subscription => subscription.unsubscribe());
  }, [auth, isReady]);

  if (auth == null) {
    throw Error('Auth-aware components must be parented by an AuthProvider.');
  }

  return { ...context, isReady } as IAuthContext;
};

/**
 * Get the current `IAuth` instance, or undefined if there is no parent
 * `AuthProvider` component.
 */
export const useAuth = (): IAuth => {
  return useAuthContext().auth;
};

/**
 * Get the current `IAuthUser` instance, or null if there is no user currently
 * signed in.
 */
export const useAuthUser = (): IAuthUser | null => {
  return useAuthContext().user;
};

/**
 * Get a boolean value indicating that it's safe to check the current auth
 * state.
 *
 * True if...
 * - Context contains an `IAuth` instance.
 * - Not in the process of handling a login redirect.
 */
export const useAuthIsReady = (): boolean => {
  return useAuthContext().isReady;
};

/**
 * Get a boolean value indicating that an auth login/token redirect is in
 * progress.
 */
export const useAuthIsRedirecting = (): boolean => {
  return useAuthContext().isRedirecting;
};

/**
 * Get the current login `Error`, or null if there is no login error.
 */
export const useAuthError = (): IAuthError | null => {
  return useAuthContext().error;
};
