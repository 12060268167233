import { ILoggerLevel } from './ILoggerLevel';

export function getLoggerLevel<T extends string = string>(name: T, value: number): ILoggerLevel<T> {
  return {
    name,
    value,
    includes: other => value <= other.value,
    toJSON: () => ({ name: name, value: Number.isNaN(value) ? null : value }),
    toString: () => name,
    valueOf: () => value
  };
}
